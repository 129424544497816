/*==========  Generic Classes  ==========*/

.hidden {
	display: none;
}

.text-center {
	text-align: center;
}

.plain-list {
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}

// Use img tag as background image with cover sizing
// Set min-height/max-height to stop extreme sizes
.background-image {
	object-fit: cover;
	width: 100%;
	object-position: center center;

	// Fallback for IE11
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
		min-height: 0px;
		max-height: none;
	}
}