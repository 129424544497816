// Basic clearfix
// Useage example
// @include clearfix();
@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

// Breakpoint
// Useage example
// @include breakpoint('max-width: 830px') {}
@mixin breakpoint($width) {
	@media ($width) {
		@content;
	}
}

// Fallback to one column CSS grid for IE11
// Default useage example. 500px width and centered.
// @include iegrid();
// Custom useage example. Both options are optional. 300px width and left aligned.
// @include iegrid(300px, false);
@mixin iegrid($width: 500px, $center: true) {
	@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
		display: block;

		> * {
			display: block;
			max-width: $width;
			margin-bottom: 30px;

			@if $center {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
